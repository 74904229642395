<template>
    <div ref="output">

    </div>
</template>
<script>
const marked = require('marked')
const renderer = new marked.Renderer();

renderer.link = function(href, title, text) {
  // Preserve the encoded href by re-encoding it if necessary
  return `<a href="${href}"${title ? ` title="${title}"` : ''}>${text}</a>`;
};

// Set the custom renderer in marked options
marked.setOptions({
  renderer: renderer,
});

export default {
    props: ['content'],

    watch: {
        content() {
            this.rebuildOutput();
        }
    },

    methods: {
        rebuildOutput() {
            let container = this.$refs.output;
            let content = marked(this.content);
            container.innerHTML = content;            
        }
    },

    mounted() {
        this.rebuildOutput();
    }
}
</script>